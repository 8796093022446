<template lang="pug">
  Transition(name="fade")
    .app-overlay-loader(v-if="state")
      FaIcon.app-overlay-loader-spinner(
        :class="size"
        icon="spinner"
        spin
      )
</template>

<script>
  export default {
    props: {
      state: {
        type: Boolean,
        default: false
      },
      size: {
        type: String,
        default: "lg"
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .app-overlay-loader
    z-index: 10000000
    position: absolute
    top: 0
    left: 0
    min-width: 100%
    width: 100%
    min-height: 100%
    height: 100%
    background: transparentize($default-white, 0.4)
    backdrop-filter: blur(1.75px)
    text-align: center

    &-spinner
      position: sticky !important
      position: fixed
      color: $default-purple

      &.lg
        font-size: 4rem
        top: calc(50% - 2rem)
      &.md
        font-size: 2rem
        top: calc(50% - 1rem)
      &.sm
        font-size: 1rem
        top: calc(50% - 0.5rem)

  /* IE11 */
  @media screen and (min-width:0\0)
    .app-overlay-loader
      &-spinner
        left: calc(50% + #{$sidebar-width} / 2 - #{$content-padding})
</style>
